.widgetContainerRight {
  width: 300px;
  height: 100%;
  overflow-y: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.networkContainer {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  width: 100%;
  // height: 100%;
  // overflow-y: auto;
}
