.mapboxgl-popup-content {
  background: var(--bs-background-pr) !important;
  padding: 5px !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: var(--bs-background-pr) !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: var(--bs-background-pr) !important;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: var(--bs-background-pr) !important;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: var(--bs-background-pr) !important;
}

.mapboxgl-ctrl-group {
  border-radius: 0px !important;
}

.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::after {
  border-left-color: var(--bs-background-pr) !important;
}

.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after {
  border-right-color: var(--bs-background-pr) !important;
}

.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after {
  border-bottom-color: var(--bs-background-pr) !important;
}

.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after {
  border-top-color: var(--bs-background-pr) !important;
}

.force-graph-container .graph-tooltip {
  transform: translate(-50%, calc(-100% - 15px)) !important;
  font-family: 'Graphik Arabic', sans-serif !important;
  font-size: 0.875rem !important;
  border-radius: 1px !important;
  color: white !important;
  padding: 8px 5px !important;
  background: rgba(0, 0, 0, 1) !important;
  text-align: center;
}
