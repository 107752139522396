.bar {
  cursor: pointer;
  transform: scaleY(1);
  stroke: #ccc;
  stroke-width: 0.5px;
  transform-origin: center center;
  transform-box: fill-box;
  transition: all 0.2s linear;
}

.bar:hover {
  transform: scaleY(1.5);
  stroke: black;
}

.axisLabel {
  font-size: 0.75rem;
  font-family: 'Graphic Arabic', arial;
}
