.container {
  height: 320px;
}

.top {
  flex-basis: 30%;
}

.icon {
  font-size: 2rem;
  color: white;
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.badge {
  right: 5px;
  top: 5px;
}
