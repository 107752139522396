.widgetContainerRight {
  width: 300px;
  height: 100%;
  overflow-y: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.widgetContainerRight.viewer > div {
  margin-top: 1rem;
}

.evolutionContainer {
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
}
