.tableTextTitle {
  font-size: 0.75rem;
}

.tableTextProp {
  font-size: 1.25rem;
}

.mapContainer {
  height: 400px;
}

.cluster {
  cursor: pointer;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
}

.cluster:hover {
  border: 2px solid black;
}

.bar {
  cursor: pointer;
  transform: scaleY(1);
  stroke: #ccc;
  stroke-width: 0.5px;
  transform-origin: center center;
  transform-box: fill-box;
  transition: all 0.2s linear;
}

.bar:hover {
  transform: scaleY(1.5);
  stroke: black;
}

.profile {
  border-radius: 0 !important;
  border: none !important;
  font-size: 1.25rem;
}

.profileActive {
  background-color: var(--bs-background-pr) !important;
  border-color: var(--bs-background-pr) !important;
  color: var(--bs-dark) !important;
}

.tableContainer {
  max-height: 200px;
  overflow-y: hidden;
  transition: max-height 1s linear;
  position: relative;
  padding-bottom: 15px;
}

.tableContainer.open {
  max-height: 2000px;
}

.tableContainer::after {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 15px;
  background: linear-gradient(
    180deg,
    rgba(254, 251, 243, 0) 0%,
    rgba(246, 245, 241, 1) 100%
  );
}

.read {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: 0.75rem;
  margin-top: 10px;
  cursor: pointer;
}

.fixedAxis {
  position: sticky;
  top: 0;
}

.barsG:hover rect,
.barsG:hover circle {
  stroke: black;
}

.barsG a {
  text-decoration: none;
}

.barsG:hover text {
  fill: black;
}

.legend {
  font-size: 0.7rem;
}

.axisLabel {
  font-size: 0.75rem;
  font-family: 'Graphic Arabic', arial;
}
