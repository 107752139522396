.barsG:hover rect,
.barsG:hover circle {
  stroke: black;
}

.barsG text {
  font-size: 0.625rem;
  fill: gray;
  font-family: 'Graphik Arabic', Arial;
}

.barsG a {
  text-decoration: none;
}

.barsG:hover text {
  fill: black;
}

.label {
  font-size: 0.75rem;
}
