@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';

$custom-colors: (
  'primary-pr': #4f7c4c,
  'secondary-pr': #b64747,
  'yellow-pr': #deb145,
  'background-pr': #f6f5f1,
  'header-pr': #eaece3,
  'filters-pr': #f5f3ec,
);

$pagination-color: $gray-900;
$pagination-active-bg: $gray-900;
$pagination-active-border-color: $gray-900;
$pagination-hover-color: $gray-900;
$form-check-input-checked-bg-color: #4f7c4c;

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

$font-family-sans-serif: 'Graphik Arabic', system-ui, -apple-system, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$headings-font-family: 'Zarid Serif', system-ui, -apple-system, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
