.card {
  cursor: pointer;
}

.card:hover .top {
  background-color: var(--bs-primary-pr);
}

.card::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.top {
  background-color: var(--bs-gray);
}

.top.active {
  background-color: var(--bs-primary-pr);
}

.icon {
  font-size: 3rem;
  color: white;
}
