.fieldName {
  font-size: 0.75rem;
}

.cross-bar-time {
  transition: all 0.2s linear;
  transition-property: height, y;
  stroke: none;
}

.cross-bar-time:hover {
  stroke: black;
}
