.cluster {
  cursor: pointer;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
}

.cluster:hover {
  border: 2px solid black;
}

.widgetContainerRight {
  width: 300px;
  height: 100%;
  overflow-y: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.evolutionContainer {
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
}
