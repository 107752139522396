.fieldName {
  font-size: 0.75rem;
}

.count {
  font-size: 0.88rem;
  font-style: normal;
}

.filter:hover .crossFilterBar {
  background-color: rgba(33, 37, 41, 1);
}

.unset {
  font-style: italic;
}

.unset .crossFilterBar {
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.5) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.5) 75%,
    transparent 75%,
    transparent
  );
  background-size: 0.3rem 0.3rem;
}

.mainFilterBar {
  height: 3px;
  background-color: rgba(33, 37, 41, 0.25);
}

.crossFilterBar {
  position: absolute;
  height: 3px;
  top: 0px;
  background-color: rgba(33, 37, 41, 0.6);
  transition: width 0.2s linear, background-color 0.2s linear;
}

.crossFilterBar.barSelected {
  position: absolute;
  height: 3px;
  top: 0px;
  background-color: rgba(33, 37, 41, 1);
  transition: width 0.2s linear, background-color 0.2s linear;
}
