@font-face {
  font-family: 'Graphik Arabic';
  src: url('./fonts/GraphikArabic-Regular-Web.woff2') format('woff2'),
    url('./fonts/GraphikArabic-Regular-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Arabic';
  src: url('./fonts/GraphikArabic-Semibold-Web.woff2') format('woff2'),
    url('./fonts/GraphikArabic-Semibold-Web.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Zarid Serif';
  src: url('./fonts/29LTZaridSerif-Regular.woff2') format('woff2'),
    url('./fonts/29LTZaridSerif-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

// Customize Bootstrap variables
@import 'variables';

// Boostrap scss
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons.css';

body {
  background-color: var(--bs-background-pr);
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
}

// Utility classes
.cursor-pointer {
  cursor: pointer;
}

body.no-body-overflow {
  overflow: hidden;
}

.padding-for-fixed-nav {
  padding-top: 67px;
}

.GraphikArabic {
  font-family: 'Graphik Arabic';
}

.ZaridSerif {
  font-family: 'Zarid Serif';
}
